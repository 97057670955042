
// CONFIG
import { apiKeys } from "./apiKeys";


// LOAD GOOGLE ANALYTICS
export function loadGoogleAnalytics() {
const script = document.createElement("script");
script.src = `https://www.googletagmanager.com/gtag/js?id=${apiKeys.googleId}`;
script.async = true;
document.head.appendChild(script);

let layer = window.dataLayer = window.dataLayer || [];

function gtag(){
    layer.push(arguments);
}

gtag('js', new Date());
gtag('config', `${apiKeys.googleId}`, {
    page_path: window.location.pathname
})
}
