


export default function Header({ LogoImg }) {

    return(
        <header className="flex-col width-full" >
            <div className="flex-col align-center logo-container">
                <div className="flex-col align-center logo">
                    <img src={LogoImg} alt="grooove logo" className="logo_img"/>
                </div>

                <span className="logo-text">make a m<span className="green-text">ooo</span>ve</span>
            </div>

            <div className="flex-col adress">
                <p className="address-text">Na Hrázi 176/17, Praha 8 - Libeň</p>
                <p className="address-text">+420 724 229 380</p>
            </div>
        </header>
    )
}