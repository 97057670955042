
import { useEffect, useState } from "react";

// CONFIG
import { loadGoogleAnalytics } from "./config/googleAnalytics";

// COMPONENTS
import Header from "./components/Header";

// HANDLE FUNCTIONS 
import { loadImage, isFontLoaded } from "./handleFunctions/loadingFunctions";

// PICTURES
import LogoImg from "./pictures/logo.svg";



function App() {

  // STATES
  const [allImagesLoaded, setAllImagesLoaded] = useState("0");

  // WAITED IMAGES
  const imageUrls = [LogoImg];

  async function loadingCheck() {
    try {

        // WAIT FOR PICTURES
        const imagePromises = imageUrls.map(loadImage);
        await Promise.all(imagePromises);

        // WAIT FOR FONT
        await isFontLoaded('silka-bold');

        // SET OPACITY TO 1 AFTER ALL IS LOADED
        setAllImagesLoaded("1");

    } catch (error) {
        console.error('Error loading images:', error);
        return "ERROR"
    }
  };


  //WAIT TO LOAD ALL IMAGES AND FONTS
  useEffect(() => {
      loadingCheck();
      // eslint-disable-next-line
  }, []);

  // LOAD GOOGLE ANALYTICS
  useEffect(() => {
    loadGoogleAnalytics();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="flex-col align-center pl-2 pr-2" >
      <div
      className="flex-col max-width"
      style={{
        opacity: allImagesLoaded,
        transition: "1s"
      }}
      >
        <Header 
        LogoImg={LogoImg} 
        />
      </div>
    </div>
  );
}

export default App;
