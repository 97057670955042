


  // IMGS LOADING FUNCTION
  export function loadImage(url){
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = url;
    });
  }


  // FONT LOADING FUNCTION
  export function isFontLoaded(fontName) {
    return new Promise((resolve, reject) => {
      // Create a new FontFace object
      //const font = new FontFace(fontName, `url(${process.env.PUBLIC_URL}/fonts/${fontName}.ttf)`);
      const font = new FontFace(fontName, `url(${process.env.PUBLIC_URL}/static/media/silka-bold.6dd1ed26fa0e22dd865f.ttf)`);

      // Load the font
      font.load().then(() => {
          resolve(true);
      }).catch(error => {
        reject(error);
      });
    });
  }